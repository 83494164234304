import React, {useState, useEffect} from "react";
import {Grid, Typography, Button, TextField, IconButton, Tooltip} from "@mui/material";
import {X} from 'react-feather';
import moment from "moment-timezone";
import EditPlotDialog from "../Dialog/EditPlotDialog";
import {useHistory} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import {$crud} from "../../factories/CrudFactory";

export default function
    CreateEnquiryPopup ({
                            selectedMapId,
                            setLocations,
                            setHoveredLocationId
                        }) {

    const history = useHistory();

    const [showTableGrid, setShowTableGrid] = useState<any>({
        housePlots: {
            show: false,
            data: []
        },
        enquires: {
            show: false,
            data: []
        }
    });

    const [openLocationDialog, setOpenLocationDialog] = useState(false);
    const [editLocationId, setEditLocationId] = useState("");

    const closeEditLocationDialog = async () => {
        setOpenLocationDialog(false);
    };

    const getAllEnquires = async ({download = false, downloadType = ""}) => {
        try{
            const {data: {total, enquires}} = await $crud.get("builder/enquiries", {
                attributes: "locationName customerDetails directionSend addressId createdAt",
                addressId: selectedMapId,
                download,
                downloadType
            });
            setShowTableGrid({...showTableGrid, enquires: {...showTableGrid.enquires, data: enquires}});
        }  catch (e) {
            console.log(e);
        }
    }

    const downloadEnquires = async () => {
        const url = `${process.env.REACT_APP_API_URL}/api/builder/enquiries?attributes=locationName customerDetails directionSend addressId createdAt&addressId=${selectedMapId}&download=true&downloadType=EXCEL&token=${localStorage.getItem("token")}`;
        window.open(url);
    }

    const getLocations = async () => {
        try{
            const {data: {locations}} = await $crud.get("builder/locations", {addressId: selectedMapId});
            setShowTableGrid({...showTableGrid, housePlots: {...showTableGrid.housePlots, data: locations}});
            setLocations(locations);
        } catch (e) {
            console.log(e);
        }
    };

    const downloadHousePlots = async () => {
        const url = `${process.env.REACT_APP_API_URL}/api/builder/locations?addressId=${selectedMapId}&download=true&downloadType=EXCEL&token=${localStorage.getItem("token")}`;
        window.open(url);
    }

    useEffect(() => {
        if(showTableGrid.housePlots.show)
            getLocations();

        if(showTableGrid.enquires.show)
            getAllEnquires({download: false, downloadType: ""});
    }, [
        showTableGrid.housePlots.show,
        showTableGrid.enquires.show,
        selectedMapId
    ]);

    return <>
        <EditPlotDialog
            open={openLocationDialog}
            onClose={closeEditLocationDialog}
            _id={editLocationId}
            getData={getLocations}
            onSuccess={() => history.push("/builder-maps")}
        />

        <div className={"builder-map-bottom-bar"}>
            <button
                // size={"small"}
                // disabled={loading}
                className="button"
                onClick={() => setShowTableGrid({
                        ...showTableGrid,
                        housePlots: {
                            ...showTableGrid.housePlots,
                            show: true
                        }
                    }
                )}
            >
                House Plots
            </button>

            <button
                // size={"small"}
                // disabled={loading}
                className="button ml-3"
                onClick={() => setShowTableGrid({
                        ...showTableGrid,
                        enquires: {
                            ...showTableGrid.enquires,
                            show: true
                        }
                    }
                )}
            >
                Enquires
            </button>
        </div>

        {
            showTableGrid.housePlots.show && <div className={"bottom-table-grid"}>
                <Grid container justifyContent={"space-between"} alignItems={"center"} className={"mb-2"} style={{
                    position: "sticky",
                    top: "-15px",
                    zIndex: "1",
                    backgroundColor: "#fff"
                }}>
                    <Grid item>
                        <Typography variant={"h6"}>House Plots</Typography>
                    </Grid>

                    <Grid item>
                        <IconButton
                            className={"mr-3"}
                            size={"small"}
                            onClick={() => downloadHousePlots()}
                        >
                            <img style={{width: "35px"}} src={"../../images/excel.svg"} />
                        </IconButton>

                        <IconButton
                            size={"small"}
                            style={{border: "1px solid black"}}
                            onClick={() => setShowTableGrid({
                                    ...showTableGrid,
                                    housePlots: {
                                        ...showTableGrid.housePlots,
                                        show: false
                                    }
                                }
                            )}
                        >
                            <X />
                        </IconButton>
                    </Grid>
                </Grid>

                <table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Area</th>
                        <th>Number</th>
                        <th>Address</th>
                        <th>Facing</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        showTableGrid.housePlots.data?.map((location) => {
                            const {builderLocationType, id, Name, plotDetails = {}} = location;
                            const {address = "", area = "", facing = "", number = ""} = plotDetails;
                            const typeName = builderLocationType === 1 ? "Quick Move-In Home"
                                : builderLocationType === 2 ? "Closed"
                                    : builderLocationType === 3 ? "In Contract" : "";
                            return <tr onMouseOver={() => setHoveredLocationId(id)} onMouseOut={() => setHoveredLocationId(null)}>
                                <td>
                                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                                        <Grid item>
                                            {Name}
                                        </Grid>

                                        <Grid item>
                                            <Tooltip title="Edit">
                                                <IconButton
                                                    onClick={() => {
                                                        setEditLocationId(id);
                                                        setOpenLocationDialog(true);
                                                    }} aria-label="edit" style={{padding: '6px', color: "orange"}}>
                                                    <EditIcon fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </td>
                                <td>{typeName}</td>
                                <td>{area}</td>
                                <td>{number}</td>
                                <td>{address}</td>
                                <td>{facing}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        }

        {
            showTableGrid.enquires.show && <div className={"bottom-table-grid"}>
                <Grid container justifyContent={"space-between"} alignItems={"center"} className={"mb-2"} style={{
                    position: "sticky",
                    top: "-15px",
                    zIndex: "1",
                    backgroundColor: "#fff"
                }}>
                    <Grid item>
                        <Typography variant={"h6"}>Enquires</Typography>
                    </Grid>

                    <Grid item>
                        <IconButton
                            className={"mr-3"}
                            size={"small"}
                            onClick={() => downloadEnquires()}
                        >
                            <img style={{width: "35px"}} src={"../../images/excel.svg"} />
                        </IconButton>

                        <IconButton
                            size={"small"}
                            style={{border: "1px solid black"}}
                            onClick={() => setShowTableGrid({
                                    ...showTableGrid,
                                    enquires: {
                                        ...showTableGrid.enquires,
                                        show: false
                                    }
                                }
                            )}
                        >
                            <X />
                        </IconButton>
                    </Grid>
                </Grid>

                <table>
                    <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone#</th>
                        <th>Zip Code</th>
                        <th>Plot Name</th>
                        <th>Location Sent By</th>
                        <th>Visit Date and time</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        showTableGrid.enquires.data?.map((enquiry) => {
                            const {customerDetails, directionSend, locationName, id, createdAt} = enquiry;
                            const {firstName = "", lastName = "", email = "", mobilePhone = "", zip = ""} = customerDetails;
                            return <tr onMouseOver={() => setHoveredLocationId(id)} onMouseOut={() => setHoveredLocationId(null)}>
                                {/*<td>*/}
                                {/*    <Grid container justifyContent={"space-between"} alignItems={"center"}>*/}
                                {/*        <Grid item>*/}
                                {/*            {Name}*/}
                                {/*        </Grid>*/}

                                {/*        <Grid item>*/}
                                {/*            <Tooltip title="Edit">*/}
                                {/*                <IconButton onClick={() => {*/}
                                {/*                    setEditLocationId(id);*/}
                                {/*                    setOpenLocationDialog(true);*/}
                                {/*                }} aria-label="edit" style={{padding: '6px', color: "orange"}}>*/}
                                {/*                    <EditIcon fontSize="medium"/>*/}
                                {/*                </IconButton>*/}
                                {/*            </Tooltip>*/}
                                {/*        </Grid>*/}
                                {/*    </Grid>*/}
                                {/*</td>*/}
                                <td>{firstName}</td>
                                <td>{lastName}</td>
                                <td>{email}</td>
                                <td>{mobilePhone}</td>
                                <td>{zip}</td>
                                <td>{locationName}</td>
                                <td>{directionSend}</td>
                                <td>{moment(createdAt).tz("UTC").format("MMM-DD-YYYY HH:mm:ss")}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        }
    </>;
}
