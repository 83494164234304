import React, {useEffect, useState, useMemo, useRef} from "react";
import {useHistory} from "react-router-dom";
import {
    Grid,
    useTheme,
    Typography,
    Switch,
    Tooltip,
    IconButton
} from "@mui/material";
import {$crud} from "../factories/CrudFactory";
import {Circle, GoogleMap, OverlayView, Polygon as PolygonComponent, Marker ,useLoadScript} from "@react-google-maps/api";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import {getBounds, getDistance, getLatitudeLongitude} from "../helpers";
import {LatLngLiteral} from "@googlemaps/google-maps-services-js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Header from "../components/Header";
import CreateEnquiryPopup from "./Dialog/CreateEnquiryPopup";
import BottomGridPopup from "./CommonComponents/BottomGridPopup";
import {Fullscreen, FullscreenExit} from '@mui/icons-material';

dayjs.extend(utc);

export default function BuilderMaps() {
    const history = useHistory();
    const [maps, setMaps] = useState<any>([]);
    let selectedBuilderMap = localStorage.getItem("selectedBuilderMap");
    const [selectedMapId, setSelectedMapId] = useState<any>(selectedBuilderMap);
    const [selectedMapData, setSelectedMapData] = useState<any>(null);
    const [center, setCenter] = useState<any>();
    const libraries = useMemo<Libraries>(() => ["geometry", "drawing", "places"], []);
    const {isLoaded} = useLoadScript({
        id: "birdview",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
        libraries
    });
    const [showLocations, setShowLocations] = useState<any>({
        quickMoveIn: true,
        closed: true,
        inContract: true,
        enquiry: true
    });

    const [hoveredLocationId, setHoveredLocationId] = useState<any>(null);

    const [selectedLocation, setSelectedLocation] = useState<any>(null);

    const [fullScreen, setFullScreen] = useState(document.fullscreenElement !== null);

    const enterFullscreen = () => {
        document.documentElement.requestFullscreen();
    };

    const exitFullscreen = () => {
        document.exitFullscreen();
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setFullScreen(document.fullscreenElement !== null);
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        setSelectedMapData(maps.find(s => s._id === selectedMapId));
    }, [selectedMapId]);

    useEffect(() => {
        if(selectedMapData){
            openBirdEye(selectedMapData);
            getLocations();
            setSelectedLocation(null);
        }

    }, [selectedMapData]);

    const getAllMaps = async () => {
        try{
            const {data: {total, maps}} = await $crud.get("builder/maps");

            setMaps(maps);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getAllMaps();
    }, []);

    const openBirdEye = async (map) => {
        let address;
        try{
            address = map.name + " " + map.area + " " + map.city + " " + map.zip;
            const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";
            const location = await getLatitudeLongitude(address, apiKey);
            setCenter({
                lat: location.lat,
                lng: location.lng
            });
        } catch (e) {
            console.log(e);
        }
    };

    const [gMap, setGMap] = useState<google.maps.Map>();
    const [zoom, setZoom] = useState(20);
    const [mapTypeId, setMapTypeId] = useState<string>(google.maps.MapTypeId.SATELLITE);
    const [locations, setLocations] = useState<any>([]);
    const [showTooltipOn, setShowTooltipOn] = useState<any>(undefined);
    const polygonRefs = useRef<Record<number, google.maps.Polygon>>({});
    const theme = useTheme();

    const getLocations = async () => {
        try{
            const {data: {locations}} = await $crud.get("builder/locations", {addressId: selectedMapId});
            setLocations(locations);
        } catch (e) {
            console.log(e);
        }
    };

    const handleMapLocationClick = (locationId) => {
        if(selectedLocation && selectedLocation === locationId)
            setSelectedLocation(null);

        else
            setSelectedLocation(locationId);
    };

    const googleMap = isLoaded && center && <GoogleMap
        onLoad={setGMap}
        id="BirdEyeViewMap"
        center={center}
        zoom={zoom}
        mapTypeId={mapTypeId}
        onMapTypeIdChanged={() => gMap && setMapTypeId(gMap.getMapTypeId()!)}
        onZoomChanged={() => gMap && setZoom(gMap.getZoom()!)}
        mapContainerStyle={
            {
                width: "100%",
                height: "100%"
            }
        }
        clickableIcons={false}
        tilt={0}
        options={{
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeId: "satellite"
        }}
    >
        {
            locations?.map((location, index) => {
                const {bounds, id, style, shape, builderLocationType, totalEnquires} = location;
                let point1, point2, heading, labelBounds;
                if(!["circle", "marker"].includes(shape)){
                    // @ts-ignore
                    [point1, point2] = bounds!.reduce<LatLngLiteral[]>((points, coords, i, array) => {
                        const nextPoint = array[i + 1] ?? array[0];
                        const dis = getDistance(coords?.lat, coords.lng, nextPoint?.lat, nextPoint.lng);
                        return !points.length || dis > getDistance(points[0]?.lat, points[0].lng, points[1]?.lat, points[1].lng) ? [coords, nextPoint] : points;
                    }, []);

                    heading = google.maps.geometry.spherical.computeHeading(new google.maps.LatLng(point1?.lat > point2?.lat ? point1 : point2), new google.maps.LatLng(point1?.lat > point2?.lat ? point2 : point1)) + 90;
                    if (heading > 0)
                        heading = heading - 180;
                    if (heading < -70)
                        heading = heading + 180;

                    let newBounds = getBounds(location.bounds!);
                    labelBounds = newBounds.bounds;
                }

                const isSelected = selectedLocation === location.id;
                const isBooked = location.booked;

                let fillColor = "#ffffcc";
                let strokeWeight = 0.5;
                let strokeColor = "#9d9d0e";
                let fillOpacity = 1;
                let showTooltip = [selectedLocation, showTooltipOn].includes(id);

                if(totalEnquires > 0){
                    fillColor = "#d313e8";
                    fillOpacity = showLocations.enquiry ? 1 : .6;
                }

                if(builderLocationType === 1){
                    fillColor = "#3e9aaf";
                    fillOpacity = showLocations.quickMoveIn ? 1 : .6;
                }

                if(builderLocationType === 2){
                    fillColor = "#ea7472";
                    fillOpacity = showLocations.closed ? 1 : .6;
                    if(!showLocations.closed)
                        showTooltip = false;
                }

                if(builderLocationType === 3){
                    fillColor = "#e89e57";
                    fillOpacity = showLocations.inContract ? 1 : .6;
                    if(!showLocations.inContract)
                        showTooltip = false;
                }

                if(isSelected){
                    fillColor = "#2d892d";
                    strokeWeight = 2;
                    strokeColor = "#0f0";
                }

                if(id === hoveredLocationId){
                    fillColor = "#e50644";
                }

                return <React.Fragment key={id}>
                    {
                        !["circle", "marker"].includes(shape) && bounds.length && <>
                            <PolygonComponent
                                onLoad={polygon => polygonRefs.current[id!] = polygon}

                                onClick={() => ![2, 3].includes(builderLocationType) && handleMapLocationClick(id)}
                                options={
                                    {
                                        strokeWeight,
                                        strokeColor,
                                        fillColor,
                                        fillOpacity,
                                        clickable: true,
                                        draggable: false,
                                        geodesic: true,
                                        editable: false
                                    }
                                }
                                onMouseOver={() => setShowTooltipOn(id)}
                                onMouseOut={() => setShowTooltipOn(undefined)}
                                key={index}
                                paths={bounds}
                            />
                            {
                                showTooltip && labelBounds && <OverlayView
                                    bounds={labelBounds}
                                    mapPaneName="overlayMouseTarget"
                                >
                                    <div
                                        style={{
                                            transform: "translateY(calc(-100% - 6px))",
                                            textAlign: "center",
                                            width: "100%"
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "100%",
                                                padding: "5px",
                                                background: "#000",
                                                color: "#fff",
                                                fontSize: "18px",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {
                                                builderLocationType === 2 ? "Closed" :
                                                    builderLocationType === 3 ? "In Contract" :
                                                        `${totalEnquires} Enquires`
                                            }
                                        </span>
                                    </div>
                                </OverlayView>
                            }

                            <OverlayView
                                bounds={labelBounds}
                                mapPaneName="overlayMouseTarget"
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    style={
                                        {
                                            width: "100%",
                                            height: "100%",
                                            transform: `rotateZ(${heading}deg)`,
                                        }
                                    }
                                >
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        style={
                                            {
                                                width: "100%",
                                                height: "100%",
                                                position: "relative",
                                            }
                                        }
                                    >
                                        <svg
                                            viewBox={`0 0 ${location?.Name?.length! * 6} 18`}
                                            style={
                                                {
                                                    width: "100%",
                                                    height: "100%",
                                                    position: "absolute"
                                                }
                                            }
                                        >
                                            <text fill={theme.palette.getContrastText(fillColor)} style={{fontSize: `${style?.labelFontSize || 10}px`}} x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">{location.Name}</text>
                                        </svg>
                                    </Grid>
                                </Grid>
                            </OverlayView>
                        </>
                    }
                    {
                        shape === "circle" && <>
                            <Circle
                                center={JSON.parse(location.circleCenter)}
                                radius={parseFloat(location.circleRadius)}
                                options={{
                                    fillColor,
                                    fillOpacity: 1,
                                    strokeWeight,
                                    strokeColor,
                                    clickable: true,
                                    zIndex: 1,
                                    draggable: false
                                }}
                                onClick={() => !location.booked && handleMapLocationClick(location.id)}

                            />
                            <OverlayView
                                position={JSON.parse(location.circleCenter)}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={(width, height) => ({
                                    x: -(width / 2),
                                    y: -(height / 2),
                                })}
                            >
                                <div
                                    style={{
                                        background: 'transparent',
                                        padding: '3px',
                                        fontSize: '15px',
                                    }}
                                >
                                    {location.Name}
                                </div>
                            </OverlayView>
                        </>
                    }
                    {
                        shape === "marker" && <Marker
                            key={index}
                            position={JSON.parse(location.bounds)}
                            onClick={() => !location.booked && handleMapLocationClick(location.id)}
                        />
                    }
                </React.Fragment>;
            })
        }
    </GoogleMap>;

    return (
        <>
            <CreateEnquiryPopup
                mapId={selectedMapId}
                location={locations?.find(s => s._id === selectedLocation)}
                onClose={() => {
                    setSelectedLocation(null);
                    getLocations();
                }}
            />

            <BottomGridPopup
                selectedMapId={selectedMapId}
                setLocations={setLocations}
                setHoveredLocationId={setHoveredLocationId}
            />

            <Header
                maps={maps}
                selectedMapId={selectedMapId}
                setSelectedMapId={setSelectedMapId}
            />

            <div className={"filter-locations-bar"}>
                <Grid container justifyContent={"space-evenly"} alignItems={"center"}>
                    <Grid item>
                        <Grid container alignItems={"center"}>
                            <Grid>
                                <div className={"circle"} style={{backgroundColor: "#3e9aaf"}}/>
                            </Grid>

                            <Grid>
                                <Typography variant={"button"}>Quick Move-In Home ({locations.filter(s => s.builderLocationType === 1)?.length})</Typography>
                            </Grid>

                            <Grid className={"ml-4"}>
                                <Switch
                                    checked={showLocations.quickMoveIn}
                                    onChange={e => {setShowLocations({...showLocations, quickMoveIn: e.target.checked})}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container alignItems={"center"}>
                            <Grid>
                                <div className={"circle"} style={{backgroundColor: "#ea7472"}}/>
                            </Grid>

                            <Grid>
                                <Typography variant={"button"}>Closed ({locations.filter(s => s.builderLocationType === 2)?.length})</Typography>
                            </Grid>

                            <Grid className={"ml-4"}>
                                <Switch
                                    checked={showLocations.closed}
                                    onChange={e => {setShowLocations({...showLocations, closed: e.target.checked})}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container alignItems={"center"}>
                            <Grid>
                                <div className={"circle"} style={{backgroundColor: "#e89e57"}}/>
                            </Grid>

                            <Grid>
                                <Typography variant={"button"}>In Contract ({locations.filter(s => s.builderLocationType === 3)?.length})</Typography>
                            </Grid>

                            <Grid className={"ml-4"}>
                                <Switch
                                    checked={showLocations.inContract}
                                    onChange={e => {setShowLocations({...showLocations, inContract: e.target.checked})}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container alignItems={"center"}>
                            <Grid>
                                <div className={"circle"} style={{backgroundColor: "#d313e8"}}/>
                            </Grid>

                            <Grid>
                                <Typography variant={"button"}>Enquiry ({locations.filter(s => s.totalEnquires > 0 && ![1, 2, 3].includes(s.builderLocationType))?.length})</Typography>
                            </Grid>

                            <Grid className={"ml-4"}>
                                <Switch
                                    checked={showLocations.enquiry}
                                    onChange={e => {setShowLocations({...showLocations, enquiry: e.target.checked})}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container alignItems={"center"}>
                            <Grid>
                                <Typography variant={"button"}>{!fullScreen ? "Full Screen" : "Exit Full Screen"}</Typography>
                            </Grid>

                            <Grid>
                                <Tooltip title="Full Screen">
                                    <div className={"icon"}>
                                        {
                                            !fullScreen ? <IconButton
                                                    onClick={enterFullscreen}
                                                >
                                                    <Fullscreen
                                                        fontSize={"large"}
                                                    />
                                                </IconButton> :
                                                <IconButton
                                                    onClick={exitFullscreen}
                                                >
                                                    <FullscreenExit
                                                        fontSize={"large"}
                                                    />
                                                </IconButton>
                                        }
                                    </div>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <div style={{ display: 'flex'}}>
                <div
                    style={{ display: 'flex', flexDirection: 'column', width: "100%", height: "calc(100vh - 146px)" }}
                >
                    <Grid container spacing={1} style={{ flexGrow: 1 }}>
                        <Grid item style={{ width: "100%", height: 'calc(100vh - 58px)' }}>
                            {
                                selectedMapId !== "" && <Grid height={"100%"} item xs={12}>
                                    {googleMap}
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};
